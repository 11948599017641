/* General Styling */
body {
  margin: 0;
  font-family: "Bebas Neue", sans-serif;
  background-color: #0b0c14;
  color: #939cb6;
  /* Primary text color */
}

/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;1,400&display=swap');

/* Font Styles */
.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
}

.roboto-regular {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Header Styling */
.header-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.header-title {
  color: #939cb6;
  /* Primary text color */
  font-size: 2.5em;
}

.header-subtitle {
  color: #939cb6;
  /* Primary text color */
  font-size: 1em;
}

/* Table Styling */
.table-header {
  cursor: pointer;
  color: #939cb6;
  /* Primary text color */
}

.table-row:hover {
  background-color: #1c1e26;
  cursor: pointer;
  color: #939cb6;
  /* Primary text color */
}

/* Navbar Styling */
.navbar {
  margin: 0;
  background-color: black;
  /* Background color */
  padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  text-align: left;
  margin-right: 10px;
}

.navbar-toggler {
  order: -1;
}

.nav-link {
  font-family: "Roboto Condensed", sans-serif;
  transition: text-decoration 0.2s ease-in-out;
  color: #939cb6;
  /* Primary text color */
}

.nav-link:hover {
  text-decoration: underline;
  color: #ffffff;
}

/* Content Box Styling */
.content-box {
  padding: 20px;
  background-color: #1c1e26;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.text-content {
  font-size: 1em;
  color: #939cb6;
  /* Primary text color */
  line-height: 1.6;
  margin-bottom: 1em;
}

/* Input and Dropdown Styling */
.form-control,
.form-select {
  border: 1px solid #939cb6;
  /* Thin line */
  color: #939cb6;
  /* Primary text color */
  background-color: #0b0c14;
  /* Match the background */
}

.form-control:hover,
.form-select:hover {
  border-color: #b0b8d1;
  /* Brighten the line on hover */
}

.slim-dropdown {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  width: auto;
  border-color: #939cb6;
}

/* Sticky Search and Filter Container */
.search-filter-container {
  position: fixed;
  top: 56px;
  /* Adjust based on navbar height */
  width: 80%;
  background-color: transparent;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
}

/* Main Content */
.main-content {
  padding-top: 50px;
  /* Adjust based on navbar + search filter height */
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 60px;
  }
}

/* Footer Styling */
.footer-box {
  background-color: black;
  padding: 20px;
}

.footer-box p {
  margin: 0;
  padding: 5px 0;
  color: #939cb6;
  /* Primary text color */
}

.footer-box a {
  color: white;
  text-decoration: underline;
}

/* Recent Design Box Styling */
.recent-design-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #1c1e26;
  width: calc(50% - 20px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  color: #939cb6;
  /* Primary text color */
}

/* Top Designers Box Styling */
.top-designer-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #1c1e26;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  color: #939cb6;
  /* Primary text color */
}