/* Home Page Main Content */
.home-main-content {
    padding-top: 20px !important;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    /* Center align the main content */
    max-width: 1200px;
    /* Set a max-width for better centering */
}

@media (max-width: 768px) {
    .home-main-content {
        padding-top: 30px !important;
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* Flexbox Styling */
.home-page-content {
    display: flex;
    flex-wrap: wrap;
}

.featured-models {
    flex: 2;
    margin-right: 1rem;
}

.stats {
    flex: 1;
    margin-left: 1rem;
}

/* Table Styling for Home Page */
.featured-table {
    cursor: pointer;
    color: #939cb6;
    border-collapse: collapse;
    /* Ensure borders are combined */
    width: 100%;
}

.featured-table thead th,
.featured-table tbody td {
    border: 1px solid #ffffff;
    /* Draw lines around all cells for debugging */
    padding: 0.5rem;
    /* Padding for better spacing */
    box-sizing: border-box;
    /* Ensure padding doesn't affect width */
    word-wrap: break-word;
    /* Allow text to wrap */
    word-break: break-word;
}

.featured-table tbody tr:hover {
    background-color: #1c1e26;
    cursor: pointer;
    color: #939cb6;
}

/* Clickable Row Styling */
.clickable-row a {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: inherit;
    text-decoration: none;
}

.clickable-link span {
    flex: 1;
    /* Allow columns to take up equal width */
    padding: 0.5rem;
    /* Padding for better spacing */
    box-sizing: border-box;
    /* Ensure padding doesn't affect width */
    text-align: center;
    /* Center align text in each span */
}

.clickable-link .model-name {
    flex: 2;
    /* Allow this column to be flexible */
}

.clickable-row:hover {
    background-color: #1c1e26;
}