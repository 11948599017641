/* Metrics Page Main Content */
.metrics-main-content {
    padding-top: 0px !important;
    /* Adjust this value to bring the content closer to the top */
}

@media (max-width: 768px) {
    .metrics-main-content {
        padding-top: 10px !important;
        /* Adjust this value for mobile view */
    }
}

/* Table Styling for Metrics Page */
.metrics-table {
    cursor: pointer;
    color: #939cb6;
}

.metrics-table tbody tr:hover {
    background-color: #1c1e26;
    cursor: pointer;
    color: #939cb6;
}

.total-cost-title {
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
}