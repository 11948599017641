/* src/Support.css */

.support-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: "Roboto", sans-serif;
}

.support-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: black;
}

.support-text {
    text-align: center;
    margin-bottom: 20px;
    color: #555;
}

.support-form .form-group {
    margin-bottom: 15px;
}

.support-form label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.support-form input,
.support-form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.support-form textarea {
    resize: vertical;
    height: 100px;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #333;
}

.success-message {
    text-align: center;
    margin-top: 20px;
    color: green;
}